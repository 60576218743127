import React, { useState } from 'react';
import axios from 'axios';

const ProfileForm = () => {
    const [profile, setProfile] = useState({
        name: '',
        gender: '',
        birthDate: '',
        occupation: '',
        preferences: {
            atmosphere: '',
            partnerAgeRange: ''
        }
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}/users/profile`, profile)
            .then(response => {
                console.log('Profile updated successfully:', response.data);
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Name:</label>
                <input type="text" name="name" value={profile.name} onChange={handleChange} required />
            </div>
            <div>
                <label>Gender:</label>
                <select name="gender" value={profile.gender} onChange={handleChange} required>
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
            </div>
            <div>
                <label>Birth Date:</label>
                <input type="date" name="birthDate" value={profile.birthDate} onChange={handleChange} required />
            </div>
            <div>
                <label>Occupation:</label>
                <input type="text" name="occupation" value={profile.occupation} onChange={handleChange} required />
            </div>
            <div>
                <label>Preferred Atmosphere:</label>
                <input type="text" name="preferences.atmosphere" value={profile.preferences.atmosphere} onChange={handleChange} required />
            </div>
            <div>
                <label>Partner Age Range:</label>
                <input type="text" name="preferences.partnerAgeRange" value={profile.preferences.partnerAgeRange} onChange={handleChange} required />
            </div>
            <button type="submit">Submit</button>
        </form>
    );
};

export default ProfileForm;


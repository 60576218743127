import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="home-page">
            <h1>Welcome to the Line Matching App</h1>
            <Link to="/profile">Complete Your Profile</Link>
            <br />
            <Link to="/chat">Go to Chat</Link>
        </div>
    );
};

export default Home;


import React from 'react';
import ProfileForm from '../components/ProfileForm';

const Profile = () => {
  return (
    <div className="profile-page">
      <h2>Complete Your Profile</h2>
      <ProfileForm />
    </div>
  );
};

export default Profile;


import React from 'react';
import ChatWindow from '../components/ChatWindow';

const Chat = () => {
  const matchId = "sample_match_id"; // 実際にはAPIなどから取得

  return (
    <div className="chat-page">
      <h2>Chat with Your Match</h2>
      <ChatWindow matchId={matchId} />
    </div>
  );
};

export default Chat;

